:root {
  --swiper-pagination-color: currentColor;
  --swiper-pagination-bullet-inactive-color: currentColor;
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --swiper-navigation-color: #fef9ee;
}

.swiper {
  padding-bottom: 40px !important; /* this allows us to move the pagination outside */
}

.swiper-pagination {
  bottom: 0 !important;
}

.swiper-button-next,
.swiper-button-prev {
  transform: translateY(-15px) !important;
}

.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-slide {
  height: auto !important;
}
