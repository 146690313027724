@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  src: url('/font/Figtree-Black.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  src: url('/font/Figtree-SemiBold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  src: url('/font/Figtree-Bold.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  src: url('/font/Figtree-Medium.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 300;
  src: url('/font/Figtree-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 200;
  src: url('/font/Figtree-Light.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 100;
  src: url('/font/Figtree-UltraLight.ttf');
  font-display: swap;
}

/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('/font/raleway-v26-latin-800.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/font/raleway-v26-latin-800.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 10vh;
}

body {
  @apply antialiased;
  scroll-behavior: smooth;
}

@layer utilities {
  .bg-inherit {
    background-color: inherit;
  }

  /* images */
  img {
    image-rendering: -webkit-optimize-contrast;
  }

  /* Add scroll snapping */

  .scroll-snap-none {
    scroll-snap-type: none;
  }

  .scroll-snap-x {
    scroll-snap-type: x mandatory;
    scroll-padding: 50%;
  }

  .snap-self-none {
    scroll-snap-align: none;
  }

  .snap-self-center {
    scroll-snap-align: center;
  }

  /*
  Currently Tailwind default `break-words` doesn't add word-break
  If this is ever added this can be removed
  */
  .break-words {
    overflow-wrap: normal;
    word-break: break-word;
  }

  /* Overflow */
  .overflow-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  /* Fix wrapper div inside portable text destroying prose */
  .prose-sm > div > :last-child {
    margin-bottom: 0;
  }
  .prose-sm > div > :first-child {
    margin-top: 0;
  }
  .prose > div > :last-child {
    margin-bottom: 0;
  }
  .prose > div > :first-child {
    margin-top: 0;
  }

  .preview {
    z-index: 999;
    position: fixed;
    right: 16px;
    top: 16px;
    padding: 6px 8px;
    background: black;
    color: white;
    font-size: 14px;
  }

  .preview:hover {
    text-decoration: underline;
  }

  .person-image-shadow {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}

@import './shadows.css';
@import './radix.css';
@import './swiper.css';

.quote-body p {
  line-height: 1.75;
}

.quote-body em {
  background-color: rgba(255, 238, 163, 0.35);
  display: inline;
  white-space: pre-wrap;
  padding: 0 4px;
  font-style: normal;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  border-radius: 2px;
}

.transform-style-3d {
  transform-style: preserve-3d;
}

canvas[resize] {
  width: 100%;
  height: 100%;
}

/* .hubspot styling */

.hubspot input,
.hubspot div input,
.hubspot div select {
  width: 100% !important;
  border: 1px solid #e6ecee;
}

.hubspot .input {
  margin-right: 0 !important;
  margin-bottom: 1rem;
}

.hubspot .input ~ ul {
  margin-top: -0.75rem;
}

.hubspot .input p,
.legal-consent-container p {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.hs-form-booleancheckbox-display > .hs-input {
  width: 16px !important;
  height: 16px !important;
  border-color: #6b7280 !important;
  margin-top: 6px;
  margin-right: 8px;
}
.hs-richtext > p {
  color: #4d7889;
}

.hs-richtext > p > a {
  color: #003e56;
  text-decoration: underline;
}

.submitted-message > p {
  font-size: larger;
  text-align: center;
}

@media (min-width: 476px) {
  .hs_firstname {
    padding-right: 0.75rem;
  }
  .hs_lastname {
    padding-left: 0.75rem;
  }
}

.hubspot .hs-richtext {
  display: none;
}

.hubspot .hs-submit {
  margin-top: 0 !important;
  padding: 2%;
}
