/* accordion */
.radix-accordion .radix-accordion-trigger svg {
  transition: transform 0.15s;
}

.radix-accordion [data-state='open'] .radix-accordion-trigger svg {
  transform: rotate(180deg);
}

@keyframes accordion-open {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion-close {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.radix-accordion [data-state='open'] .radix-accordion-content {
  animation: accordion-open 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.radix-accordion [data-state='closed'] .radix-accordion-content {
  animation: accordion-close 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

/* Accordions inside prose */

.prose .radix-accordion .radix-accordion-title {
  margin: 0;
  font-size: inherit;
}

.prose .radix-accordion .radix-accordion-trigger {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

.prose .radix-accordion {
  margin: 3rem 0;
  filter: none;
}

.radix-scroll-margin {
  scroll-margin-top: 200px;
}
